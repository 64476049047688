
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://5dea4716a90a4568954bc0c911cf236b@sentry.wixpress.com/2013',
      id: '5dea4716a90a4568954bc0c911cf236b',
      projectName: 'albums-uou-app',
      teamName: 'albums',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":["albums"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/cd0418694237b2be/packages/albums-uou-app/src/assets/locales/messages_en.json","availableLanguages":["de","en"]};

  var defaultTranslations = {"app.hello":"Hello","ALBUM_SHARE_MAILTO_SUBJECT":"I wanted you to see this site","ALBUM_SHARE_MAILTO_BODY":"Check out this site%0D%0A {shareUrl}."};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/cd0418694237b2be/packages/albums-uou-app/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "albums-uou-app",
    biConfig: null,
    appName: "albums-uou-app",
    appDefinitionId: "d3e94e97-1218-4796-aa05-c9a6c68ecb16",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["albums"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/cd0418694237b2be/packages/albums-uou-app/src/components/App/controller.ts",
          appName: "albums-uou-app",
          appDefinitionId: "d3e94e97-1218-4796-aa05-c9a6c68ecb16",
          projectName: "albums-uou-app",
          componentName: "App",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "1078f2d8-ae0e-4dd1-a0ef-105c44b16066" }],
    false);

    export const createControllers = _createControllers
